import Modal from 'flarum/components/Modal';
import ForgotPasswordModal from 'flarum/components/ForgotPasswordModal';
import SignUpModal from 'flarum/components/SignUpModal';
import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import Select from 'flarum/components/Select';

export default class AddWishlistNameModal extends Modal {
    //Used for Alert Modal
    init() {
        super.init();
    }

    className() {
        return 'Modal--Big brandModal';
    }

    title() {
        return app.translator.trans("Create a new list");
    }

    hide() {
        $('.item-alertsort select.Select-input.FormControl').val("");
        $("#dataerror").html("");
        app.modal.close();
    }
    content() {
        return [
            m('div', { className: 'Modal-body WishListNameBox' }, [
                m('div', { id: 'dataerror', style: { color: "red" } }, ""),
                m('div', { id: 'wishlistname', }, "List Name"),
                m('input[type=text][name=topicname][id=topicname]', ({ value: "" }))
            ]),

            m("a", ({ className: 'button button-sm btn-danger cc_buyNowBtn_alert', onclick: this.AddWishlistName.bind(this) }), "Create List")

        ];
    }

    AddWishlistName(AlertData) {
        $("#dataerror").html("");
        var userId = app.session.user.data.id;
        var WishListName = $("#topicname").val();
        var valid = true;
        if (WishListName == "") {
            $("#dataerror").html("Please enter a name for the list.");
            valid = false;
        }
        if (valid) {
            const Api_url = app.forum.attribute('baseUrl') + '/api/createWishlistName';
            return app.request({
                method: 'POST',
                url: Api_url,
                data: { userId: userId, WishListName: WishListName },
            }).then(
                this.parseResults.bind(this),
            );
        } else {
            $("#dataerror").html("Please enter a name for the list.");
        }
    }

    parseResults(result) {
        if (result.data.attributes.message == "WishList Name Already Exits") {
            $("#dataerror").css("color", "Red").html("WishList Name Already Exits");
        } else if (result.data.attributes.message == "Added WishList Name") {
            $("#dataerror").css("color", "Blue").html("Added WishList Name");
        } else if (result.data.attributes.message == "Not inserted") {
            $("#dataerror").css("color", "Red").html("Please enter a name for the list");
        } else {
            ("#dataerror").html("");
        }
    }

    config(isInitialized) {
    }

}//end