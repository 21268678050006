import Page from "flarum/components/Page";
import EndpointsApiUrls from "../EndpointsApiUrls";
import CouponDetailsStructre from "../coupon/CouponDetailsStructre";
import CategoryListData from "./../categoryHome/CategoryListData";
import StoreOfferList from "../coupon/StoreOfferList";
import ScrollListener from "flarum/utils/ScrollListener";
import Placeholder from "flarum/components/Placeholder";
import DealPlaceHolder from "../DealPlaceHolder";
import listItems from "flarum/helpers/listItems";
import ItemList from "flarum/utils/ItemList";
import Button from "flarum/components/Button";

export default class CouponStorePage extends Page {
  init() {
    super.init();
    this.loading = true;
    this.StoreName = m.route.param("tags");
    this.AllCoupon = "active";
    this.Couponlist = "";
    this.Offerlist = "";
    this.type = "AllCoupon";
    this.flag = 1;
    this.loadFeaturedCoupons();
    this.CouponInnerDatacc = [];
    this.ImgPath = "";
    let params = {};
    params.stores = m.route.param("tags");
    this.StoreOfferList = new StoreOfferList({ params });
    this.loadCouponInformation();
    this.couponData = [];
    this.scrollListener = new ScrollListener(this.onScroll.bind(this));
    this.loader = true;
    this.couponDataList();
    this.offerDataList();
    this.coupondatalength = "";
    this.offerdatalength = "";
  }

  requestParams(offset) {
    const params = {};
    if (offset != undefined) {
      params.size = offset;
    }
    return params;
  }

  loadFeaturedCoupons(perpage) {
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    const params = this.requestParams(perpage ? perpage + 1 : 0);

    params.query = "coupon";
    params.stores = this.StoreName;
    // params.size = 0;

    //if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
    var Api_url = ApiEndpoint + "/searchforcoupon";
    // } else {
    //   var Api_url = ApiEndpoint + "/api/v3/coupons";
    // }

    app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.couponresult.bind(this));
  }

  loadCouponInformation() {
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    //if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
    var Api_url = ApiEndpoint + "/getStores?type=coupons";
    // } else {
    //   var Api_url = ApiEndpoint + "/api/v3/coupons/stores?type=coupons";
    // }

    app
      .request({
        method: "GET",
        url: Api_url,
      })
      .then(this.couponinformation.bind(this));
  }

  view() {
    const CouponInnerDataaa = this.CouponInnerDatacc;
    const StoreImgPath = this.ImgPath;
    const CouponStoreName = this.StoreName.toUpperCase();
    const text =
      "It looks like there are currently no coupons available for this search. ";
    let loading;
    if (this.loading) {
      loading = listItems(this.getPlaceHolders(20).toArray());
    } else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans(
          "core.forum.discussion_list.load_more_button"
        ),
        className: "Button",
        onclick: this.loadMore.bind(this),
      });
    }

    if (!this.CategoryListData) {
      let params = {};
      params.filter = this.getCatDealParams(this.StoreName);
      params.heading = "Trending Deals On " + this.StoreName.toUpperCase();
      params.headingIcon = "fa fa-clock-o";
      params.tag = this.StoreName;
      params.stores = this.StoreName;
      params.headingName = this.StoreName;
      params.routename = this.props.routeName;
      this.CategoryListData = new CategoryListData({ params });
    }

    //  if (this.CouponInnerDatacc.length) {
    //   const MerchantName = this.CouponInnerDatacc[0]._source.merchantname;
    //   this.CouponName = this.CouponInnerDatacc[0]._source.tag_slug;


    return (
      <div>
        {this.StoreOfferList.discussions.length > 0 ?
          <div class="myDeals" style="background-color:#2d3e50;">
            {this.StoreOfferList.render()}
          </div>
          : <div class="storeCopunheading">{CouponStoreName}</div>
        }
        <div class="storeimgouter" style="">
          <div class="couponstoreimg" style="">
            {this.couponData.store_logo != undefined && this.couponData.store_logo != "" ?
              (<img src={this.couponData.store_logo} style="height:28px;margin: 45px 0px;" />)
              : (<span style="text-transform: capitalize;">
                {CouponStoreName}
              </span>)
            }
          </div>
          {this.couponData != undefined && this.couponData.about ?
            <div class="couponstoreinfo" style="margin-top:10px;border: Solid 1px #d3d3d3;padding: 15px;border-radius: 10px;">
              <div>{m.trust(this.couponData.about)}</div>
            </div>
            : ""
          }
        </div>
        <div class="couponrightouter" style="">
          {this.CategoryListData != undefined && this.CategoryListData.DealList.length > 0 ?
            <div class="myDeals storecouponnew" style="overflow: hidden;">
              {this.CategoryListData.render()}
            </div>
            : ""
          }

          {this.CouponInnerDatacc.length > 0 || this.loading ? (
            <div className="container">
              {/* <div class="tab" style="background-color: #607d8b !important;">
                <button id="AllCoupon" class={this.AllCoupon ? "tablinks active" : "tablinks"} value="AllCoupon" onclick={this.listtype.bind(this)}>All</button>
                {this.coupondatalength > 0 ? < button id="Couponlist" class={this.Couponlist ? "tablinks active" : "tablinks"} value="Couponlist" onclick={this.listtype.bind(this)}>Coupons</button> : ""}
                {this.offerdatalength > 0 ? <button id="Offerlist" class={this.Offerlist ? "tablinks active" : "tablinks"} value="Offerlist" onclick={this.listtype.bind(this)}>Offers</button> : ""}

              </div> */}
              <div class="">
                <ul className="DiscussionList-discussions">
                  {this.loading
                    ? loading
                    : this.CouponInnerDatacc.map((discussion) => {
                      return (
                        <li>
                          <div class="" style="">
                            {CouponDetailsStructre.component({
                              discussion,
                            })}
                          </div>
                        </li>
                      );
                    })}
                  <li>
                    {this.CouponInnerDatacc.length % 20 === 0 && this.CouponInnerDatacc.length > 0 && this.loader
                      ? DealPlaceHolder.component({})
                      : ""}
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="DiscussionList">
              {Placeholder.component({ text })}
            </div>
          )}
        </div>
      </div>
    );
    // } else {
    //   //this.loading = true;
    //   return (
    //     // <div class="row">
    //     //   <div className="DiscussionList-loadMore">{loading}</div>
    //     // </div>
    //     <div className="DiscussionList">
    //       {!this.loading ?
    //         Placeholder.component({ text })
    //         : ""
    //       }
    //     </div>
    //   );
    // }
  }

  couponresult(results) {
    if (results.result.hits) {
      if (this.flag > 1) {
        this.CouponInnerDatacc = [];
      }
      const CouponInnerData = results.result.hits.hits;
      [].push.apply(this.CouponInnerDatacc, CouponInnerData);
      //this.CouponInnerDatacc = CouponInnerData;
      this.ImgPath = CouponInnerData[0]._source.imagepath;

      this.loading = false;
      this.moreResults = true;
      m.redraw();
      return results;
    } else {
      this.loading = false;
      this.loader = false;
      m.redraw();
    }
    // return CouponInnerData;
  }

  getCatDealParams(fparam) {
    const params = { include: ["startUser", "lastUser", "tags"], filter: {} };
    params.filter.q = "is:deal tag:" + fparam;
    params.include.push(
      "relevantPosts",
      "relevantPosts.discussion",
      "relevantPosts.user"
    );
    return params;
  }

  couponinformation(results) {
    const couponlist = results["coupon_store_list"];
    couponlist.map((value) => {
      if (value.key.toLowerCase() == m.route.param("tags").toLowerCase()) {
        this.couponData = value;
      }
    });
    m.lazyRedraw();
  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add(
        "palceholder-" + i.toString(),
        DealPlaceHolder.component()
      );
    }
    return placeHolderList;
  }

  onScroll(pos) {
    if (this.CouponInnerDatacc.length % 20 === 0 && !this.loading) {
      if (pos >= $(document).height() - $(window).height() - 450) {
        this.loadMore();
      }
    }
  }
  config(isInitialized, context) {
    if (isInitialized) return;
    setTimeout(() => this.scrollListener.start());
    context.onunload = () => {
      this.scrollListener.stop();
    };
  }
  loadMore() {
    this.loading = true;
    this.loadFeaturedCoupons(this.CouponInnerDatacc.length);
  }

  listtype(data) {
    if (data.target.value == "Couponlist") {
      this.Couponlist = "active";
      this.AllCoupon = "";
      this.Offerlist = "";
      this.type = "Couponlist";
    } else if (data.target.value == "Offerlist") {
      this.Couponlist = "";
      this.AllCoupon = "";
      this.Offerlist = "active";
      this.type = "Offerlist";
    } else {
      this.Couponlist = "";
      this.AllCoupon = "active";
      this.Offerlist = "";
      this.type = "AllCoupon";
    }
    this.flag++;
    const perpage = 0;
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    const params = this.requestParams(perpage ? perpage + 1 : 0);

    params.query = "coupon";
    params.stores = this.StoreName;
    if (this.type == "Offerlist") {
      params.hasPromoCode = false;
    } else if (this.type == "Couponlist") {
      params.hasPromoCode = true;
    }
    // params.size = 0;

    //if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
    var Api_url = ApiEndpoint + "/searchforcoupon";
    // } else {
    //   var Api_url = ApiEndpoint + "/api/v3/coupons";
    // }

    app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.couponresult.bind(this));

  }

  couponDataList() {
    this.type = "Couponlist";

    //this.flag++;
    const perpage = 0;
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    const params = this.requestParams(perpage ? perpage + 1 : 0);

    params.query = "coupon";
    params.stores = this.StoreName;
    if (this.type == "Offerlist") {
      params.hasPromoCode = false;
    } else if (this.type == "Couponlist") {
      params.hasPromoCode = true;
    }
    // params.size = 0;

    //if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
    var Api_url = ApiEndpoint + "/searchforcoupon";
    // } else {
    //   var Api_url = ApiEndpoint + "/api/v3/coupons";
    // }

    app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.couponresultdata.bind(this));
  }

  couponresultdata(results) {
    if (results.result.hits) {
      this.coupondatalength = results.result.hits.hits.length;
    } else {
      this.coupondatalength = 0;
    }
  }

  offerDataList() {
    this.type = "Offerlist";

    //this.flag++;
    const perpage = 0;
    const ApiEndpoint = new EndpointsApiUrls().getSearchUrl("searchAPI");
    const params = this.requestParams(perpage ? perpage + 1 : 0);

    params.query = "coupon";
    params.stores = this.StoreName;
    if (this.type == "Offerlist") {
      params.hasPromoCode = false;
    } else if (this.type == "Couponlist") {
      params.hasPromoCode = true;
    }
    // params.size = 0;

    //if (app.forum.attribute("baseUrl") == "https://thedealapp.com/") {
    var Api_url = ApiEndpoint + "/searchforcoupon";
    // } else {
    //   var Api_url = ApiEndpoint + "/api/v3/coupons";
    // }

    app
      .request({
        method: "GET",
        url: Api_url,
        data: params,
      })
      .then(this.offerresultdata.bind(this));
  }

  offerresultdata(results) {
    if (results.result.hits) {
      this.offerdatalength = results.result.hits.hits.length;
    } else {
      this.offerdatalength = 0;
    }
  }

}
