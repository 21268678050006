import Page from 'flarum/components/Page';
// import WishListName from './WishListName';
// import SearchName from './SearchName';
import WishList from './WishList';

export default class WishListIndex extends Page {
  //used for display wishlist
  init() {
    super.init();
    this.params = m.route.param();
    let id = app.session.user.data.id;
    var params = this.params;
    params.id = id;
    this.WishList = new WishList({ params });
    //this.WishListName = new WishListName({ id: id, routeName: this.props.routeName });
    //this.SearchName = new SearchName({id:id,routeName:this.props.routeName});
    this.flag = true;
    this.WishListNamesd = "active";
    this.searcsd = "";
  }

  view() {
    /* <strong class="topicLabel">Topics:</strong>
               <hr/>*/
    return (
      m('div',
        <div className="container">
          <div>
            <h2 class="cc_productDetailTitle">My Wishlist</h2>
          </div>
          <div>
            {this.WishList.render()}
          </div>
          {/* {this.flag == true ? */}
          {/* <div class="IndexPage-results" id="WishListName"> */}
          {/* {this.WishListName ? this.WishListName.render() :"" }   */}
          {/* </div> */}
          {/* :
            <div class="IndexPage-results" id="search"> */}
          {/* {this.SearchName ? this.SearchName.render() :"" } */}
          {/* </div>} */}
        </div>
      )
    )
  }

  listtype(data) {
    if (data.target.value == "Search By Keywords") {
      this.flag = false;
      this.searcsd = "";
      $("#WishListName").hide();
      $("#search").show();
      this.WishListNamesd = "";
      this.searcsd = "active";
    } else {
      this.flag = true;
      $("#WishListName").show();
      $("#search").hide();
      this.WishListNamesd = "active";
      this.searcsd = "";
    }
  }

}//end
