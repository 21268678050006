import Component from "flarum/Component";
import slidable from "flarum/utils/slidable";
import Badge from "flarum/components/Badge";
import WishlistModal from "./modal/WishlistModal";
import NewLoginModal from "./modal/NewLogin";

export default class ProductCard extends Component {
  // used to display products on search,category,pricedrop,similar product,brand pages
  init() { }
  setData() {
    this.merchantName =
      this.props.ProductData.merchantName != undefined
        ? this.props.ProductData.merchantName.toLowerCase()
        : "";
    this.tags = app.store.getBy(
      "tags",
      "slug",
      this.merchantName ? this.merchantName : ""
    );
    this.noImageFound =
      app.forum.data.attributes.baseUrl + "/assets/images/no_image_found.png";
    this.expiredStamp =
      app.forum.data.attributes.baseUrl + "/assets/images/out_of_stock.png";
    this.slug = m.prop(this.props.ProductData.slug || "");
    this.title = m.prop(this.props.ProductData.title || "");
    this.product_id = m.prop(this.props.ProductData.product_id || "");
    this.maxPrice = m.prop(this.props.ProductData.maxPrice || "");
    this.currentPrice = m.prop(this.props.ProductData.currentPrice || "");
    this.productOutOfStock = m.prop(this.props.ProductData.productOutOfStock);
    this.discount = m.prop(this.props.ProductData.discount || "");
    this.PrevioussellingPrice = m.prop(
      this.props.ProductData.PrevioussellingPrice || ""
    );
    this.sellingPrice = m.prop(this.props.ProductData.sellingPrice || "");
    this.url = m.prop(this.props.ProductData.url || "");
    this.imageUrl_sm = m.prop(this.props.ProductData.imageUrl_sm || "");
    var urlcontent = this.imageUrl_sm().split('/');
    if (urlcontent[0] == "http:") {
      urlcontent[0] = "https:";
    }
    this.imageUrl = urlcontent.join('/');
    this.startUser = m.prop(this.props.ProductData.startUser || "");
    this.pricedrop =
      ((this.PrevioussellingPrice() - this.sellingPrice()) /
        this.PrevioussellingPrice()) *
      100;
    this.customBadge = null;
    if (this.discount() > 0) {
      this.customBadge = Math.round(this.discount()) + "% OFF";
    }
    this.storeimagepath = this.tags ? this.tags.data.attributes.imagePath : "";
    this.Hotness = m.prop(this.props.ProductData.Hotness || "");
    this.fulfilledByFlag = m.prop(this.props.ProductData.fulfilledBy || "");
    this.amazonfulfilled =
      app.forum.data.attributes.baseUrl +
      "/assets/images/fullfilled/amazon.png";
    this.flipkartfulfilled =
      app.forum.data.attributes.baseUrl +
      "/assets/images/fullfilled/flipkart.png";
    this.secondarytag = m.prop(this.props.ProductData.secondarytag || "");
    this.LastUpdatedOn = m.prop(
      this.props.ProductData.LastUpdatedOn
        ? this.props.ProductData.LastUpdatedOn.$date.$numberLong
        : ""
    );
    this.lastupdatedon = m.prop(this.props.ProductData.lastupdatedon || "");
    this.tid = m.prop(this.props.ProductData.tid || "");
  }
  view() {
    var tags = this.tags ? this.tags : "";
    const link = true;
    this.setData();
    let MerchantName = m.prop(
      this.props.ProductData.merchantName
        ? this.props.ProductData.merchantName
        : ""
    );
    var updateddate = new Date(this.lastupdatedon());
    let lastupdateddate = new Date(parseInt(this.LastUpdatedOn())).toString();
    return m(
      "div",
      { class: "cc_productCardSingle" },
      <div className="DiscussionListItem_DCM">
        <div className={"DiscussionListItem-content"}>
          <div id={"product_card"} itemscope itemtype="http://schema.org/Product">
            <a
              href={
                app.forum.data.attributes.baseUrl +
                "/product_details/" +
                this.slug()
              }
              config={m.route}
            >
              <div class="cc_imageBox">
                <div class="cc_imageFrameDealPage">
                  {this.imageUrl ? (
                    <img
                      itemprop="image"
                      className="sm_img lazy"
                      src={this.imageUrl.replace("rukmini1", "rukminim1")}
                      alt="Product Image"
                      onError={"this.src='" + this.storeimagepath + "'"}
                    />
                  ) : (
                    <img
                      itemprop="image"
                      className="sm_img lazy"
                      src={this.storeimagepath}
                      alt="no image found"
                    />
                  )}
                </div>
              </div>
              {!this.productOutOfStock() == true ? (
                ""
              ) : (
                <div class="cc_expiredStamp_mini">
                  <img
                    className="sm_img lazy"
                    src={this.expiredStamp}
                    alt="expired Stamp"
                  />
                </div>
              )}
            </a>
            <div class="cc_detailsBox_mini">
              <a
                href={
                  app.forum.data.attributes.baseUrl +
                  "/product_details/" +
                  this.slug()
                }
                config={m.route}
              >
                <div
                  itemprop="offers"
                  itemscope
                  itemtype="http://schema.org/Offer"
                >
                  {this.maxPrice() != this.currentPrice() ? (
                    <div class="cc_offerText_DCM">
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      {this.maxPrice()}
                    </div>
                  ) : (
                    <div class="cc_offerText"></div>
                  )}
                  {this.PrevioussellingPrice() !== "" &&
                    this.sellingPrice() !== "" ? (
                    this.pricedrop !== 0 &&
                      this.pricedrop !== 100 &&
                      this.pricedrop !== NaN &&
                      this.pricedrop > 0 ? (
                      <div class="pricedrop">
                        {parseFloat(this.pricedrop).toFixed(0)}%{" "}
                        <i class="fas fa-arrow-down"></i>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {this.currentPrice() ? (
                    <div class="cc_productPrice_DCM">
                      <i itemprop="priceCurrency" content="Rs"></i>
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      <span itemprop="price">
                        {parseFloat(this.currentPrice())
                          .toFixed(2)
                          .replace(/\.0+$/, "")}
                      </span>
                      /-
                    </div>
                  ) : (
                    <div class="cc_productPrice" style="visibility:hidden">
                      {app.translator.trans(
                        "flarum-myindex.lib.number_suffix.currency"
                      )}
                      <span itemprop="price">
                        {parseFloat(this.currentPrice())
                          .toFixed(2)
                          .replace(/\.0+$/, "")}
                      </span>
                      /-
                    </div>
                  )}
                  {lastupdateddate != "Invalid Date" ? (
                    <div
                      style="display: inline-block;padding-left: 6px;"
                      class="miniInfoIcon"
                    >
                      <Badge
                        data-html="true"
                        type="hidden"
                        icon="fa fa-info"
                        label={
                          "Price as of " +
                          lastupdateddate +
                          "<br />Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                          MerchantName() +
                          " at the time of purchase will apply to the purchase of this product."
                        }
                      />
                    </div>
                  ) : (
                    <div
                      style="display: inline-block;padding-left: 6px;"
                      class="miniInfoIcon"
                    >
                      <Badge
                        data-html="true"
                        type="hidden"
                        icon="fa fa-info"
                        label={
                          "Price as of " +
                          updateddate +
                          "<br />Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on " +
                          MerchantName() +
                          " at the time of purchase will apply to the purchase of this product."
                        }
                      />
                    </div>
                  )}
                </div>
                <div class="cc_textBold cc_titleBox_DCM">
                  <h3 class="cc_titleText_DCM" itemprop="name">
                    {m.trust(this.title())}
                  </h3>
                </div>
              </a>
              <div class="cc_bottomBar_DCM">
                <div class="cc_siteIcons_DCM">
                  {this.tags ? (
                    <span class="TagsLabel ">
                      <a class="" title="" href={"/t/" + this.merchantName}>
                        <span class="TagLabel-text">
                          <img
                            src={this.tags.data.attributes.imagePath}
                            height="16.5em"
                          />
                        </span>
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {this.url() ? (
                  <div class="dealCardMini_Btn">
                    <a
                      class="Button--square Button--buySmall hasIcon"
                      href={this.url()}
                      target="_blank" rel="nofollow"
                    >
                      BUY NOW
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {this.customBadge ? (
              <div class="cc_hotness_DCM">{this.customBadge}</div>
            ) : (
              ""
            )}
            {this.Hotness() > 0.5 ? (
              <div class="cc_hotness_DCM1" style="color:orangered;">
                <i class="fa fa-fire"></i>
                {" " + Math.round(this.Hotness())}
              </div>
            ) : (
              ""
            )}
            {this.fulfilledByFlag().toLowerCase() == "store" ? (
              <div class="cc_fulfilledBy">
                {this.merchantName == "flipkart" ? (
                  <img
                    style="max-height:20px"
                    src={this.flipkartfulfilled}
                    alt="fullfilled"
                  />
                ) : (
                  ""
                )}
                {this.merchantName == "amazon" ? (
                  <img
                    style="max-height:20px"
                    src={this.amazonfulfilled}
                    alt="fullfilled"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <div class="cc_secondarytag">
              {this.secondarytag != "" ? <div>{this.secondarytag()}</div> : ""}
            </div>
            {/* <div class="cc_addbookmark">
              <button id="bookmark" value={this.tid()} data-cmd="yes" class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.addBookmark.bind(this)}><i class="far fa-bookmark" aria-hidden="true"></i></button>
            </div>
            <div class="cc_button pull-right">
              <button style="display:none;" id={this.tid()} class="Button  Button--primary mysubmit cc_mysubmit cc_hidebutton" onclick={this.Compareproduct.bind(this)}><i class="fa fa-bell-o" aria-hidden="true"></i></button>
            </div> */}
            <div style="clear:both;"></div>
          </div>
        </div>
      </div>
    );
  }

  config(isInitialized) {
    if (isInitialized) return;

  }

  // addBookmark(data) {
  //   if (app.session.user) {
  //     var Data = [];
  //     var curPrice = this.currentPrice();
  //     var tid = this.tid();
  //     var title = this.title();
  //     var userId = app.session.user.data.id;
  //     var WishListName = "General";
  //     this.userinput = "";
  //     var Alertproduct = "No";
  //     var id = "";
  //     var Store = "";
  //     Data.push({ userId: userId, tid: tid, OldPrice: curPrice, title: title, WishListName: WishListName, UserInput: this.userinput, Alertproduct: Alertproduct, Id: id, Store: Store });

  //     if ($('#bookmark').attr('data-cmd') == "yes" && data.path[1].value == this.props.ProductData.tid) {
  //       const Api_url = app.forum.attribute('baseUrl') + '/api/productalert';
  //       return app.request({
  //         method: 'POST',
  //         url: Api_url,
  //         data: Data,
  //       }).then(
  //         this.parseResults.bind(this),
  //       );
  //     } else {
  //       document.getElementById(this.props.ProductData.tid).style.display = "none";
  //       $('#bookmark').attr('data-cmd', "yes");
  //     }
  //   } else {
  //     app.modal.show(new NewLoginModal());
  //   }
  // }

  // Compareproduct(data) {
  //   var product = this.props.ProductData;
  //   app.modal.show(
  //     new WishlistModal({ product })
  //   );
  // }

  // parseResults(result) {
  //   if (result.data.attributes.message == "inserted") {
  //     document.getElementById(this.tid()).style.display = "block";
  //     document.getElementById(this.tid()).innerHTML = 'Added to WishList <i class="fa fa-bell-o" aria-hidden="true"></i>';
  //     $('#bookmark').attr('data-cmd', "no");
  //   } else if (result.data.attributes.message == "Record Already Insearted") {
  //     document.getElementById(this.tid()).style.display = "block";
  //     document.getElementById(this.tid()).innerHTML = 'Already Added to Your WishList. <i class="fa fa-bell-o" aria-hidden="true"></i>';
  //     $('#bookmark').attr('data-cmd', "no");
  //   }
  // }

}
