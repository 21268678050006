import Modal from 'flarum/components/Modal';
import ForgotPasswordModal from 'flarum/components/ForgotPasswordModal';
import SignUpModal from 'flarum/components/SignUpModal';
import listItems from 'flarum/helpers/listItems';
import ItemList from 'flarum/utils/ItemList';
import Select from 'flarum/components/Select';

export default class WishlistModal extends Modal {
  //Used for Alert Modal
  init() {
    super.init();
    this.product = this.props.product;
  }

  className() {
    return 'Modal--Big wishlistModal';
  }

  title() {
    return app.translator.trans("");
  }

  hide() {
    app.modal.close();
  }
  content() {
    var ProductData = this.product;
    if (ProductData.tid != undefined) {
      this.tid = ProductData.tid;
    } else {
      this.tid = "";
    }
    if (ProductData.id != undefined) {
      this.id = ProductData.id;
    } else {
      this.id = "";
    }
    if (ProductData.sellingPrice != undefined) {
      this.currentprice = ProductData.sellingPrice;
    } else if (ProductData.currentPrice != undefined) {
      this.currentprice = ProductData.currentPrice;
    }
    if (ProductData.tag_slug != undefined) {
      this.store = ProductData.tag_slug;
    } else {
      this.store = "";
    }
    this.mrp = ProductData.maxPrice;
    this.title1 = ProductData.title;
    return [
      <div className="Modal-body wishlistmodalbox">
        <div className="Modal-body">
          <div id='dataerror' style="color:red;"></div>
          <div class="cc_textBold cc_titleBox_DCM">
            <h2 class="cc_titleText_DCM" itemprop="name">
              {m.trust(this.title1)} - <i itemprop="priceCurrency" content="Rs"></i>
              {app.translator.trans(
                "flarum-myindex.lib.number_suffix.currency"
              )}
              <span itemprop="price">
                {parseFloat(this.currentprice)
                  .toFixed(2)
                  .replace(/\.0+$/, "")}
              </span>
                      /-
                  </h2>
          </div>
          <div class="cc_targetprice">
            <input type="text" value="" id="userinputdata" className="userinputdata" placeholder="Enter Target Price"></input>
          </div><br />
          <div class="alertcheckbox">
            <input type="checkbox" id="myCheck" /><span>  Alert me smiliar item on other sites</span>
          </div>
        </div>
      </div>,

      <div className="Modal-footer">
        <input
          type="button"
          class="Button Button--primary Button--block"
          id="submitwishlist"
          value="Submit"
          onclick={this.onSubmit.bind(this)}
        /></div>,
    ];
  }

  /**
   * Open the forgot password modal, prefilling it with an email if the user has
   * entered one.
   *
   * @public
   */
  forgotPassword() {
    app.modal.show(new ForgotPasswordModal(props));
  }

  /**
   * Open the sign up modal, prefilling it with an email/username/password if
   * the user has entered one.
   *
   * @public
   */
  signUp() {

    app.modal.show(new SignUpModal(props));
  }

  onSubmit(wishlistdata) {
    $("#dataerror").html("");
    var Data = [];
    var curPrice = this.currentprice;
    var Mrp = this.mrp;
    var tid = this.tid;
    var title = this.title1;
    var userId = app.session.user.data.id;
    var WishListName = "General";
    var numbers = /^(\d{1,})+$/;
    this.userinput = $(".userinputdata").val();
    var Alertproduct;
    var id = this.id;
    var Store = this.store;

    if (this.userinput == "") {
      $("#dataerror").html("Please Enter the Targeted Price");
    } else {
      if (this.userinput.match(numbers)) {
        if (this.userinput < curPrice && this.userinput > 0) {
          $("#dataerror").html("");
        } else {
          $("#dataerror").html("Please input price Less than Current Price & greater than 0");
        }
      } else {
        $("#dataerror").html("Please input numeric characters without decimal(.)");
      }
    }

    if ($('#myCheck').prop("checked") == true) {
      Alertproduct = "Yes";
    } else {
      Alertproduct = "No";
    }

    Data.push({ userId: userId, tid: tid, OldPrice: curPrice, title: title, WishListName: WishListName, UserInput: this.userinput, Alertproduct: Alertproduct, Id: id, Store: Store });

    const Api_url = app.forum.attribute('baseUrl') + '/api/productalert';
    return app.request({
      method: 'POST',
      url: Api_url,
      data: Data,
    }).then(
      this.parseResults.bind(this),
    );

  }

  parseResults(result) {
    if (result.data.attributes.message == "inserted") {
      $("#dataerror").css("color", "Blue").html("Thank You for your input. We will Notify you when prices are change");
      document.getElementById(this.tid).style.display = "none";
    } else if (result.data.attributes.message == "Not inserted") {
      $("#dataerror").html("Product is not added in your wishlist. please fill the correct data");
      document.getElementById(this.tid).style.display = "block";
    } else if (result.data.attributes.message == "Record Already Insearted") {
      $("#dataerror").html("Product is already Present in Your Wishlist");
      document.getElementById(this.tid).style.display = "block";
    } else if (result.data.attributes.message == "Record Updated successfully") {
      $("#dataerror").html("Product Updated successfully");
      document.getElementById(this.tid).style.display = "block";
    } else {
      ("#dataerror").html("");
    }
  }

}//end