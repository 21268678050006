import Component from 'flarum/Component';
import Button from 'flarum/components/Button';
import WishListCard from './WishListCard';
import ItemList from 'flarum/utils/ItemList';
import listItems from 'flarum/helpers/listItems';
import DealPlaceHolder from '../DealPlaceHolder';
import SlickSlider from '../SlickmainSlider';

export default class WishList extends Component {//start
  init() {
    this.params = this.props.params;
    this.headingName = this.props.params.headingName;
    const routeName = this.props.routeName;
    this.loading = true;
    this.loader = true;
    this.userid = this.props.id;
    this.refresh();
    this.moreResults = false;
    this.list = [];
    this.listimage = [];
    this.listdata = [];
    this.listseemore = [];
    this.listname = "";
    this.flag = 0;
  }
  view() {
    this.Alllist = [];
    const text = "It looks like there are currently no products available for this search. ";
    let loading;

    if (this.loading) {
      loading = listItems(this.getPlaceHolders(22).toArray());
    }
    else if (this.moreResults) {
      loading = Button.component({
        children: app.translator.trans('core.forum.discussion_list.load_more_button'),
        className: 'Button',
        onclick: this.loadMore.bind(this)
      });
    }

    if (this.list.length === 0 && !this.loading) {
      const text = app.translator.trans('core.forum.discussion_list.empty_text');
      return (
        <div className="DiscussionList">

        </div>
      );
    }
    let wishlistname = this.listname != "" ? this.listname : "";
    // this.listdata = this.list.reduce(function (result, product) {
    //   var ProductData = {
    //     title: product.attributes.title,
    //     product_id: product.attributes.id,
    //     maxPrice: product.attributes.maxPrice,
    //     currentPrice: product.attributes.sellingPrice,
    //     productOutOfStock: product.attributes.productOutOfStock,
    //     discount: product.attributes.discount,
    //     url: app.forum.data.attributes.baseUrl + '/blossom?bid=' + product.attributes.linkid.$oid + '&url=' + product.attributes.productURL + '&src=product',
    //     imageUrl_sm: product.attributes.imageUrl_sm,
    //     merchantName: product.attributes.merchantName,
    //     slug: product.attributes.slug,
    //     tid: product.attributes.tid,
    //     PriceTracker: product.attributes.PriceTracker,
    //     tdaCategory: product.attributes.tdaCategory,
    //   };

    //   result.push(ProductCard.component({ ProductData }));

    //   return result;
    // }, []);
    //     if(this.headingName){
    //           this.listseemore  = new Array(
    //                 m("div",{class: "dealCard_mini",style: "text-align:center; padding:58px"},[
    //                       m("div",{class: "DiscussionListItem-content"},[       
    //                             m("a", { href:'/Wishlist/'+this.headingName,config:m.route},"SEE MORE"
    //                             )
    //                       ])
    //                 ])
    //           );
    //     }

    //  if(this.listdata){
    //      this.Alllist = this.Alllist.concat(this.listdata);
    //  }
    //  if(this.headingName){
    //      this.Alllist = this.Alllist.concat(this.listseemore);
    //  }
    return (
      <div>
        <div className="DiscussionList">
          <div class="productlist1">
            <ul className="DiscussionList-discussions cc_dealList1">
              {this.loading
                ? loading
                : this.list.map((product) => {
                  var ProductData = {
                    title: product.attributes.title,
                    product_id: product.id,
                    maxPrice: product.attributes.maxPrice,
                    currentPrice: product.attributes.sellingPrice,
                    productOutOfStock:
                      product.attributes.productOutOfStock,
                    discount: product.attributes.discount,
                    url:
                      app.forum.data.attributes.baseUrl +
                      "/blossom?bid=" +
                      product.attributes.linkid.$oid +
                      "&url=" +
                      product.attributes.productURL +
                      "&src=product",
                    imageUrl_sm: product.attributes.imageUrl_sm,
                    merchantName: product.attributes.merchantName,
                    slug: product.attributes.slug,
                    PriceTracker: product.attributes.PriceTracker,
                    Hotness: product.attributes.Hotness,
                    fulfilledBy: product.attributes.fulfilledBy,
                    secondarytag: product.attributes.secondarytag,
                    LastUpdatedOn: product.attributes.LastUpdatedOn,
                    tid: product.attributes.tid,
                  };
                  return (
                    <li class="cc_productCard">
                      {WishListCard.component({ ProductData, wishlistname })}
                    </li>
                  );
                })}
              <li>
                {this.list.length > 0 && this.loader
                  ? DealPlaceHolder.component({})
                  : ""}
              </li>
            </ul>
            <div style="clear:both"></div>
          </div>
        </div>
        <div style="clear:both"></div>
      </div>
    );

  }

  getPlaceHolders(size) {
    const placeHolderList = new ItemList();
    for (var i = 0; i < size; i++) {
      placeHolderList.add('palceholder-' + i.toString(), DealPlaceHolder.component());
    }
    return placeHolderList;
  }
  loadMore() {
    this.loading = true;
    this.loadResults(this.list.length);
  }
  config(isInitialized, context) {
    if (!isInitialized) return;
  }
  refresh(clear = true) {
    if (clear) {
      this.loading = true;
      this.list = [];
    }

    return this.loadResults();
  }
  loadResults(perpage) {
    const params = this.requestParams(perpage ? perpage + 1 : 0);
    const Api_url = app.forum.attribute('baseUrl') + '/api/wishlist';
    return app.request({
      method: 'GET',
      url: Api_url,
      data: params,
    }).then(
      this.parseResults.bind(this),
    );
  }

  requestParams(offset) {
    let params = this.params;
    return params;
  }

  parseResults(results) {
    [].push.apply(this.list, results.data);
    this.getwishlistname();
    this.loading = false;
    this.moreResults = true;
    m.redraw();
    return results;
  }

  getwishlistname() {
    var userId = app.session.user.data.id;
    if (this.flag == 0) {
      this.flag++;
      const Api_url = app.forum.data.attributes.baseUrl + '/api/getWishlistName';
      return app.request({
        method: "GET",
        url: Api_url,
        data: { userId: userId },
      }).then(
        this.getDetails.bind(this),
      );
    }
  }

  getDetails(result) {
    this.listname = result.data;
    m.lazyRedraw();
  }

}//end
